import React, { useEffect, useCallback, useState } from 'react';
import axios from 'axios';
import { Switch, withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import AppBar from './AppBar';
import UploadOrders from './upload-orders/UploadOrders.js';
import ResetPassword from './reset-password/ResetPassword.js';
import Users from './users/Users.js';
import Login from './login/Login.js';
import NoInternetDialog from './common/NoInternetDialog';
import NoAuthRoute from './common/NoAuthRoute';
import AuthRoute from './common/AuthRoute';
import AdminRoute from './common/AdminRoute';
import FeedbackPaper from './common/FeedbackPaper';
import { parseJWT, getRandomColor } from '../util';
import useReducerWithLogger from '../hooks/useReducerWithLogger';

const useStyles = makeStyles(theme => ({
  content: {
    flex: 1,
    display: 'flex',
  },
  progressWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.user };
    case 'SET_TOKEN':
      return {
        ...state,
        token: action.token,
        tokenExp: action.tokenExp,
        authenticated: true,
      };
    case 'SET_SET_USER_FUNCTION':
      return { ...state, setUser: action.fn };
    case 'SET_USER':
      return { ...state, user: action.user };
    case 'LOGOUT':
      return {
        ...state,
        authenticated: false,
        user: {},
        token: '',
        tokenExp: null,
      };
    case 'TOGGLE_ONLINE':
      return { ...state, online: action.online };
    case 'TOGGLE_LOADING':
      return { ...state, loading: action.show };
    case 'INIT_START':
      return { ...state, error: false };
    case 'INIT_FAIL':
      return { ...state, error: true };
    default:
      throw new Error(`wut action? ${action.type}`);
  }
};

const initialState = {
  authenticated: false,
  token: '',
  online: true,
  user: {},
  userColor: getRandomColor(),
  setUser: () => null,
  loading: false,
  error: false,
  tokenExp: null,
};

const token = localStorage.getItem('token');
if (token) {
  initialState.token = token;
  initialState.loading = true;
}

export const AppContext = React.createContext(initialState);

function App({ history }) {
  const [refresh, setRefresh] = useState(null);
  const [state, dispatch] = useReducerWithLogger(reducer, initialState);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { token, online, authenticated, loading, error, tokenExp } = state;

  // Logout whenever we get an response with 401/403 Status.
  const removeAuth = axios.interceptors.response.use(
    response => Promise.resolve(response),
    error => {
      if (error.response && [401, 403].includes(error.response.status)) {
        onLogout();
      }
      return Promise.reject(error);
    }
  );

  const setSetUser = useCallback(() => {
    dispatch({
      type: 'SET_SET_USER_FUNCTION',
      fn: ({ id, username, admin, email }) => {
        dispatch({
          type: 'SET_USER',
          user: { id, username, admin, email },
        });
      },
    });
  }, [dispatch]);

  const setToken = useCallback(
    token => {
      const jwtContent = parseJWT(token);
      localStorage.setItem('token', token);
      dispatch({ type: 'SET_TOKEN', token, tokenExp: jwtContent.exp });
    },
    [dispatch]
  );

  const onLoginSuccess = useCallback(
    async token => {
      dispatch({ type: 'INIT_START' });
      try {
        const jwtContent = parseJWT(token);
        let res = await axios.get(`/users/${jwtContent.id}`, {
          headers: { Authorization: 'bearer ' + token },
        });
        dispatch({
          type: 'LOGIN',
          user: res.data,
        });
        setSetUser();
        enqueueSnackbar(`Hello ${res.data.username} :)`, {
          variant: 'success',
        });
        setToken(token);
      } catch (e) {
        dispatch({ type: 'INIT_FAIL' });
        enqueueSnackbar('could not get user infos.', { variant: 'error' });
      } finally {
        dispatch({ type: 'TOGGLE_LOADING', show: false });
      }
    },
    [dispatch, enqueueSnackbar, setSetUser, setToken]
  );

  const refreshToken = useCallback(async () => {
    try {
      let res = await axios.get('/refresh-token');
      setToken(res.data.token);
    } catch (e) {
      console.log('could not refresh token'); // can be ignored for now.
    }
  }, [setToken]);

  // init effect
  useEffect(() => {
    const onOffline = () => dispatch({ type: 'TOGGLE_ONLINE', online: false });
    const onOnline = () => dispatch({ type: 'TOGGLE_ONLINE', online: true });

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);
    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
      axios.interceptors.response.eject(removeAuth);
    };
  }, [dispatch, removeAuth]);

  // Auth token effect
  useEffect(() => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    if (token && !authenticated) {
      onLoginSuccess(token);
    }
  }, [onLoginSuccess, token, authenticated]);

  // Refresh Token Effect
  useEffect(() => {
    if (tokenExp) {
      const tokenTtlSec = (new Date(tokenExp * 1000) - new Date()) / 1000;
      const delayRefreshToken = tokenTtlSec > 30 ? tokenTtlSec - 30 : 0;
      const id = setTimeout(refreshToken, delayRefreshToken * 1000);
      setRefresh(id);
    }
  }, [refreshToken, setRefresh, tokenExp]);

  const onLogout = () => {
    axios.defaults.headers.common['Authorization'] = '';
    localStorage.removeItem('token');
    clearTimeout(refresh);
    setRefresh(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AppContext.Provider value={state}>
      <CssBaseline />
      {loading && (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )}

      {error && (
        <Container maxWidth="md" className={classes.progressWrapper}>
          <FeedbackPaper variant="error">
            <Typography component="p" align="center">
              Uups. Something went wrong while initializing the page. Please
              reload the page.
            </Typography>
          </FeedbackPaper>
        </Container>
      )}

      {!loading && !error && (
        <React.Fragment>
          <AppBar onLogout={onLogout} />
          <Container maxWidth="lg" className={classes.content}>
            <Switch>
              <NoAuthRoute path="/login" authenticated={state.authenticated}>
                <Login onSuccess={onLoginSuccess} />
              </NoAuthRoute>

              <NoAuthRoute
                path="/reset-password"
                authenticated={state.authenticated}
              >
                <ResetPassword />
              </NoAuthRoute>

              <AdminRoute path="/users" isAdmin={state.user.admin}>
                <Users />
              </AdminRoute>

              <AuthRoute path="/" authenticated={state.authenticated}>
                <UploadOrders />
              </AuthRoute>
            </Switch>
          </Container>
        </React.Fragment>
      )}

      <NoInternetDialog open={!online} />
    </AppContext.Provider>
  );
}

export default withRouter(App);
