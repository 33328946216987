import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import PropTypes from 'prop-types';

import FeedbackPaper from '../common/FeedbackPaper';
import { parseJWT } from '../../util';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const ResetPasswordForm = ({ token, onSuccess }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const classes = useStyles();
  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    const headers = { Authorization: 'bearer ' + token };
    try {
      const jwtContent = parseJWT(token);
      await axios.post(
        `/reset-password/${jwtContent.id}`,
        { password },
        { headers }
      );
      setPassword('');
      onSuccess();
    } catch (e) {
      setError(e);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Typography
        variant="h4"
        align="center"
        color="textSecondary"
        paragraph={true}
      >
        Reset Password
      </Typography>

      {!error && <Divider />}

      {error && (
        <FeedbackPaper variant="error">
          <Typography component="p">
            Snap. Something went wrong. Please try again.
          </Typography>
        </FeedbackPaper>
      )}

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <TextField
          className={classes.textField}
          required
          autoFocus={true}
          variant="outlined"
          label="New Password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          margin="normal"
          type="password"
        />

        <Button
          disabled={!password || loading}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
        >
          Set Password
        </Button>
      </form>
    </React.Fragment>
  );
};

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ResetPasswordForm;
