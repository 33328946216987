import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import PropTypes from 'prop-types';

import FeedbackPaper from '../common/FeedbackPaper';
import useReducerWithLogger from '../../hooks/useReducerWithLogger';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  fieldWithMargin: { marginBottom: theme.spacing(2) },
  loading: {
    position: 'relative',
  },
  linkWrapper: {
    margin: theme.spacing(2, 0),
    color: theme.palette.secondary.main,
    fontSize: '1.2em',
    textAlign: 'end',
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_VALUE':
      state = { ...state };
      state[action.label] = action.value;
      return state;
    case 'SUBMIT':
      return { ...state, loading: true, errorStatus: null };
    case 'LOGIN_FAIL':
      return {
        ...state,
        loading: false,
        errorStatus: action.status,
      };
    case 'LOGIN_SUCCESS':
      return { ...state, loading: false };
    case 'SET_PASSWORD_DIRTY':
      return { ...state, passwordDirty: true };
    case 'SET_USERNAME_DIRTY':
      return { ...state, usernameDirty: true };
    default:
      throw Error(`wut the action? ${action.type}`);
  }
};

const initalState = {
  loading: false,
  username: '',
  password: '',
  errorStatus: null,
  passwordDirty: false,
  usernameDirty: false,
};

const defaultError = 'Ups. Something went wrong.';
const errors = {
  403: 'Looks like Password and/or Username are wrong.',
  500: 'Whhops. Service is unavailable, why not try again later?',
};

const Login = ({ onSuccess }) => {
  const classes = useStyles();
  const [state, dispatch] = useReducerWithLogger(reducer, initalState);
  const onSubmit = async e => {
    const { username, password } = state;
    e.preventDefault();

    // not valid
    if (!username || !password) return;

    dispatch({ type: 'SUBMIT' });
    let res;
    try {
      res = await axios.post('/login', { username, password });
      const { token } = res.data;
      dispatch({ type: 'LOGIN_SUCCESS' });
      onSuccess(token);
    } catch (e) {
      const status = e.response ? e.response.status : 500;
      dispatch({ type: 'LOGIN_FAIL', error: e, status });
    }
  };

  let errorMsg;
  if (state.errorStatus) {
    errorMsg = errors[state.errorStatus] || defaultError;
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Typography
          variant="h2"
          align="center"
          color="textSecondary"
          paragraph={true}
        >
          Sign In
        </Typography>

        {!errorMsg && !state.loading && <Divider />}

        {state.loading && <LinearProgress color="secondary" />}

        {errorMsg && <FeedbackPaper variant="error">{errorMsg}</FeedbackPaper>}

        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <TextField
            error={!state.username && state.usernameDirty}
            required
            autoFocus={true}
            onBlur={() => dispatch({ type: 'SET_USERNAME_DIRTY' })}
            variant="outlined"
            label="Name"
            value={state.username}
            onChange={e =>
              dispatch({
                type: 'UPDATE_VALUE',
                label: 'username',
                value: e.target.value,
              })
            }
            margin="normal"
          />

          <TextField
            error={!state.password && state.passwordDirty}
            required
            variant="outlined"
            label="Password"
            placeholder="password"
            margin="normal"
            classes={{ root: classes.fieldWithMargin }}
            value={state.password}
            type="password"
            onBlur={() => dispatch({ type: 'SET_PASSWORD_DIRTY' })}
            onChange={e =>
              dispatch({
                type: 'UPDATE_VALUE',
                label: 'password',
                value: e.target.value,
              })
            }
          />

          <Button
            disabled={!(state.username && state.password) || state.loading}
            size="large"
            variant="contained"
            color="primary"
            type="submit"
          >
            Log In.
          </Button>
        </form>
        <div className={classes.linkWrapper}>
          <Link to="/reset-password">Forgot Password?</Link>
        </div>
      </Container>
    </div>
  );
};

Login.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default Login;
