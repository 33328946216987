import React, { useCallback, useContext } from 'react';
import axios from 'axios';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import { red } from '@material-ui/core/colors';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { useSnackbar } from 'notistack';

import UserAvatar from './UserAvatar';
import DeleteUserConfirm from './DeleteUserConfirm';
import UserFormDialog from './UserFormDialog';
import AsyncInit from '../common/AsyncInit';
import FeedbackPaper from '../common/FeedbackPaper';
import useReducerWithLogger from '../../hooks/useReducerWithLogger';

import { AppContext } from '../App';

const initialState = {
  users: [],
  error: null,
  loading: false,
  contextUser: null,
  showConfirmDialog: false,
  showUserFormDialog: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT_DATA_SUCCESS':
      return { ...state, users: action.users };
    case 'TOGGLE_LOADING':
      return { ...state, loading: action.show };
    case 'DELETE_USER_START':
      return { ...state, error: null, loading: true };
    case 'DELETE_USER_SUCCESS':
      return {
        ...state,
        users: action.users,
        loading: false,
        contextUser: null,
      };
    case 'DELETE_USER_FAIL':
      return {
        ...state,
        error: action.error,
        loading: false,
        contextUser: null,
      };
    case 'TOGGLE_DELETE_CONFIRM':
      return {
        ...state,
        showConfirmDialog: action.show,
        contextUser: action.contextUser,
        error: null,
      };
    case 'TOGGLE_USER_FORM':
      return {
        ...state,
        showUserFormDialog: action.show,
        contextUser: action.contextUser,
        error: null,
      };
    case 'UPSERT_USER_FAIL':
      return { ...state, error: action.error };
    default:
      throw new Error(`wut the action?: ${action.type}`);
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  listRoot: {
    width: '100%',
  },
  iconButtonDanger: {
    margin: theme.spacing(1),
    color: red[500],
    '&:hover': {
      background: red[100],
    },
  },
  title: {
    flexGrow: 1,
  },
  header: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(1, 0),
  },
  divider: {
    width: '100%',
  },
  progress: {
    position: 'relative',
    width: '100%',
  },
}));

const Users = () => {
  const [state, dispatch] = useReducerWithLogger(reducer, initialState);
  const { users } = state;
  const classes = useStyles();
  const context = useContext(AppContext);
  const { enqueueSnackbar } = useSnackbar();

  const init = useCallback(async () => {
    try {
      let res = await axios.get('/users');
      res.data.sort(sortByUsername);
      const users = res.data.filter(u => u.id !== context.user.id);
      dispatch({
        type: 'INIT_DATA_SUCCESS',
        users: users,
      });
      return Promise.resolve(res);
    } catch (e) {
      return Promise.reject(e);
    }
  }, [context.user.id, dispatch]);

  const sortByUsername = (a, b) => {
    if (a.username.toLowerCase() > b.username.toLowerCase()) {
      return 1;
    }
    return -1;
  };

  const deleteUser = async user => {
    const users = [...state.users];
    const index = users.findIndex(u => u.id === user.id);
    dispatch({ type: 'DELETE_USER_START' });
    dispatch({ type: 'TOGGLE_DELETE_CONFIRM', show: false });
    try {
      await axios.delete(`/users/${user.id}`);
      users.splice(index, 1);
      dispatch({ type: 'DELETE_USER_SUCCESS', users });
      enqueueSnackbar(`Deleted user ${user.username}`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Could not delete ${user.username}`, {
        variant: 'error',
      });
      dispatch({ type: 'DELETE_USER_FAIL', error: e });
    }
  };

  const showDeleteUserConfirm = (user, show = true) => {
    dispatch({ type: 'TOGGLE_DELETE_CONFIRM', show, contextUser: user });
  };

  const cancelDeleteUser = () => {
    dispatch({ type: 'TOGGLE_DELETE_CONFIRM', show: false, contextUser: null });
  };

  const handleSubmitUserForm = async formValues => {
    dispatch({ type: 'TOGGLE_LOADING', show: true });
    dispatch({ type: 'TOGGLE_USER_FORM', show: false, contextUser: null });
    const { id, username, password, admin, email } = formValues;
    const user = { id, username, password, admin, email };
    const url = user.id ? `/users/${user.id}` : '/users';
    try {
      await axios.post(url, user);
      await init();
      enqueueSnackbar(`${user.id ? 'Updated' : 'Created'} user`, {
        variant: 'success',
      });
    } catch (e) {
      dispatch({ type: 'UPSERT_USER_FAIL', error: e });
      enqueueSnackbar(`Could not ${user.id ? 'update' : 'create'} user`, {
        variant: 'error',
      });
    } finally {
      dispatch({ type: 'TOGGLE_LOADING', show: false });
    }
  };

  const renderError = () => {
    const { error } = state;
    if (!error) return null;

    let msg = 'Something went wrong. Please try again later!';
    if (error.response) {
      const { data } = error.response;
      if (data && data.error) {
        msg = data.error;
      }
    }

    return (
      <FeedbackPaper variant="error">
        {error.response && (
          <Typography variant="h6" component="p" align="center">
            {error.response.statusText} [{error.response.status}]
          </Typography>
        )}

        <Typography component="p" align="center">
          {msg}
        </Typography>
      </FeedbackPaper>
    );
  };

  return (
    <AsyncInit onInit={init}>
      <div className={classes.root}>
        {renderError()}
        <div className={classes.header}>
          <Typography variant="h4" component="h1" className={classes.title}>
            Users
          </Typography>
          <Fab
            onClick={() =>
              dispatch({
                type: 'TOGGLE_USER_FORM',
                show: true,
                contextUser: null,
              })
            }
            color="secondary"
            aria-label="add"
            className={classes.fab}
          >
            <AddIcon />
          </Fab>
        </div>

        {!state.loading && <Divider className={classes.divider} />}

        {state.loading && (
          <LinearProgress color="secondary" className={classes.progress} />
        )}

        <List dense className={classes.listRoot}>
          {users.map((user, i) => (
            <ListItem
              key={i}
              role={undefined}
              onClick={() =>
                dispatch({
                  type: 'TOGGLE_USER_FORM',
                  show: true,
                  contextUser: user,
                })
              }
              dense
              button
            >
              <ListItemAvatar>
                <UserAvatar
                  user={user}
                  background={
                    user.id === context.user.id ? context.userColor : null
                  }
                />
              </ListItemAvatar>
              <ListItemText id={user.id} primary={user.username} />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => showDeleteUserConfirm(user)}
                  className={classes.iconButtonDanger}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>{' '}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>

      <UserFormDialog
        onSubmit={handleSubmitUserForm}
        open={state.showUserFormDialog}
        onCancel={() =>
          dispatch({ type: 'TOGGLE_USER_FORM', show: false, contextUser: null })
        }
        user={state.contextUser}
      />

      <DeleteUserConfirm
        open={state.showConfirmDialog}
        user={state.contextUser}
        onProceed={() => deleteUser(state.contextUser)}
        onCancel={cancelDeleteUser}
      />
    </AsyncInit>
  );
};

export default Users;
