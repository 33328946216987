import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import FeedbackPaper from './../common/FeedbackPaper';

const OrderFeedbackPaper = props => {
  const { variant, statusText, status, files } = props;
  return (
    <FeedbackPaper variant={variant}>
      <Typography component="p">Status: {status}</Typography>
      <Typography component="p">{statusText}</Typography>
      <Typography component="p">
        File(s): {files.map(file => file.name).join(', ')}
      </Typography>
    </FeedbackPaper>
  );
};

OrderFeedbackPaper.propTypes = {
  variant: PropTypes.string,
  statusText: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

OrderFeedbackPaper.defaultProps = {
  files: [],
};

export default OrderFeedbackPaper;
