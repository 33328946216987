import React, { useCallback } from 'react';
import axios from 'axios';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import papa from 'papaparse';
import { saveAs } from 'file-saver';
import { useSnackbar } from 'notistack';

import FileActions from './FileActions';
import Dropzone from '../common/Dropzone';
import AsyncInit from '../common/AsyncInit';
import UploadFeedbackDialog from '../upload-feedback/UploadFeedbackDialog';
import ParseConfirmDialog from '../parse-confirm/ParseConfirmDialog';
import useReducerWithLogger from '../../hooks/useReducerWithLogger';
import { fromProfitexCSV } from '../../picqer';

const initialState = {
  files: [],
  loading: false,
  showConfirmDialog: false,
  showFeedbackDialog: false,
  uploadFailures: [],
  uploadSuccesss: [],
  validOrders: [],
  invalidOrders: [],
  dupOrders: [],
  orderIndex: 0,
  warehouses: [],
  customers: [],
  orders: [],
  products: [],
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  rootPaper: {
    padding: theme.spacing(2, 2),
    width: '100%',
  },
  progress: {
    width: '100%',
  },
}));

function reducer(state, action) {
  switch (action.type) {
    case 'SET_FILES':
      return { ...state, files: action.files };
    case 'START_UPLOAD':
      return { ...state, loading: true, isUploading: true };
    case 'STOP_UPLOAD':
      return {
        ...state,
        loading: false,
        uploadFailures: action.failures,
        uploadSuccesss: action.successs,
        isUploading: false,
      };
    case 'START_PARSE':
      return { ...state, loading: true };
    case 'STOP_PARSE':
      return { ...state, loading: false };
    case 'TOGGLE_CONFIRM_DIALOG':
      return { ...state, showConfirmDialog: action.show };
    case 'TOGGLE_FEEDBACK_DIALOG':
      return { ...state, showFeedbackDialog: action.show };
    case 'UPLOAD_PROGRESS':
      return { ...state, orderIndex: action.orderIndex };
    case 'SET_ORDERS':
      return {
        ...state,
        validOrders: action.validOrders,
        invalidOrders: action.invalidOrders,
        dupOrders: action.dupOrders,
      };
    case 'INIT_DATA_SUCCESS':
      return {
        ...state,
        error: false,
        customers: action.customers,
        warehouses: action.warehouses,
        orders: action.orders,
        products: action.products,
      };
    default:
      throw new Error();
  }
}

function UploadOrders() {
  const [state, dispatch] = useReducerWithLogger(reducer, initialState);
  const { warehouses, customers, products } = state;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const getAll = async (url, config = {}) => {
    let done = false;
    const out = [];

    if (!config.params) config.params = {};
    config.params = { ...config.params, offset: 0 };
    while (!done) {
      let res = await axios.get(url, config);
      if (res.data.length) {
        out.push(...res.data);
        config.params.offset += 100;
      } else {
        done = true;
      }
    }

    return out;
  };

  const init = useCallback(async () => {
    try {
      let warehouseRes = await axios.get('/picqer/warehouses');
      let customerData = await getAll('/picqer/customers');
      let orderData = await getAll('/picqer/orders');
      let productData = await getAll('/picqer/products');

      dispatch({
        type: 'INIT_DATA_SUCCESS',
        customers: customerData,
        warehouses: warehouseRes.data,
        products: productData,
        orders: orderData,
      });
      return Promise.resolve();
    } catch (e) {
      return Promise.reject(e);
    }
  }, [dispatch]);

  const onFileDrop = files => {
    const csvFiles = files.filter(file => file.name.includes('.csv'));
    if (csvFiles.length) {
      dispatch({ type: 'SET_FILES', files: [...state.files, ...csvFiles] });
    }
  };

  const deleteFile = index => {
    const newFiles = [...state.files];
    newFiles.splice(index, 1);
    dispatch({ type: 'SET_FILES', files: newFiles });
  };

  const parseFiles = async () => {
    dispatch({ type: 'START_PARSE' });
    try {
      const { orders, failures, duplicates } = await fromProfitexCSV(
        state.warehouses,
        state.customers,
        state.orders,
        state.products,
        ...state.files
      );
      dispatch({
        type: 'SET_ORDERS',
        validOrders: orders,
        invalidOrders: failures,
        dupOrders: duplicates,
      });
      dispatch({ type: 'TOGGLE_CONFIRM_DIALOG', show: true });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Could not parse Files.', { variant: 'error' });
    } finally {
      dispatch({ type: 'STOP_PARSE' });
    }
  };

  const handleCloseConfirm = () => {
    dispatch({ type: 'TOGGLE_CONFIRM_DIALOG', show: false });
    dispatch({
      type: 'SET_ORDERS',
      validOrders: [],
      invalidOrders: [],
      dupOrders: [],
    });
  };

  const uploadOrders = async () => {
    dispatch({ type: 'START_UPLOAD' });
    dispatch({ type: 'TOGGLE_CONFIRM_DIALOG', show: false });

    const failures = [];
    const successs = [];
    for (let [i, order] of state.validOrders.entries()) {
      let res;
      try {
        res = await order.upload();
        successs.push({ res, order });
      } catch (e) {
        failures.push({ res: e.response, order });
      } finally {
        dispatch({ type: 'UPLOAD_PROGRESS', orderIndex: i + 1 });
      }
    }

    dispatch({ type: 'STOP_UPLOAD', successs, failures });
    dispatch({ type: 'TOGGLE_FEEDBACK_DIALOG', show: true });
  };

  const handleFeedbackClose = () => {
    dispatch({ type: 'TOGGLE_FEEDBACK_DIALOG', show: false });
    dispatch({
      type: 'SET_ORDERS',
      validOrders: [],
      invalidOrders: [],
      dupOrders: [],
    });
  };

  const exportCsv = (items, filename) => {
    const orders = items.map(item => item.order);
    let unMerged = [...orders];
    orders.forEach(order => {
      unMerged = [...unMerged, ...order.unMergeProducts()];
    });

    const csvRows = unMerged.map(order =>
      order.toCSVRow(warehouses, customers, products)
    );
    const unparsed = papa.unparse(csvRows, { delimiter: ';' });
    const blob = new Blob([unparsed], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${filename}.csv`);
  };

  return (
    <AsyncInit onInit={init}>
      <div className={classes.root}>
        <Paper className={classes.rootPaper}>
          <FileActions
            isDisabled={state.loading || !state.files.length}
            files={state.files}
            onDeleteFile={deleteFile}
            onSubmit={parseFiles}
          />

          <Dropzone isDisabled={state.loading} onFileDrop={onFileDrop}>
            {state.isUploading && (
              <React.Fragment>
                <Typography variant="h5" color="primary" component="p">
                  {(
                    (state.orderIndex / state.validOrders.length) *
                    100
                  ).toFixed(2)}
                  %
                </Typography>
              </React.Fragment>
            )}
          </Dropzone>
        </Paper>
        {state.loading && (
          <LinearProgress color="secondary" className={classes.progress} />
        )}

        <UploadFeedbackDialog
          open={state.showFeedbackDialog}
          failures={state.uploadFailures}
          successs={state.uploadSuccesss}
          onClose={handleFeedbackClose}
          onExport={exportCsv}
        />

        <ParseConfirmDialog
          open={state.showConfirmDialog}
          onCancel={handleCloseConfirm}
          onProceed={uploadOrders}
          orders={state.validOrders}
          invalidOrders={state.invalidOrders}
          duplicateOrders={state.dupOrders}
        />
      </div>
    </AsyncInit>
  );
}

UploadOrders.propTypes = {};

export default UploadOrders;
