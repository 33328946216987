import { parseFileAsync } from '../util';
import Product from './Product';
import Order from './Order';

const CARGO_CARE_TAG_ID = 42161;
const CH_WAREHOUSE_TAG_ID = 42463;
const ATRIKOM_WAREHOUSE_ID = 5060;
const GURMELS_WAREHOUSE_ID = 5008;

export const fromProfitexCSV = async (
  warehouses,
  customers,
  orders,
  products,
  ...files
) => {
  const newOrders = [];
  const duplicates = [];
  const failures = [];
  const promises = files.map(parseFileAsync);
  const res = await Promise.all(promises);

  for (const item of res) {
    const { data, file } = item;
    for (let row of data) {
      const {
        customerID,
        referenceID,
        productCode,
        amount,
        price,
        warehouseNames,
        tags,
      } = parseRow(row);

      // Find Customer
      const customer = customers.find(
        c => String(c.customerid) === String(customerID)
      );

      // Map Warehouse-Names to Warehouse-IDs
      const warehouseIDs = warehouseNames.reduce((acc, name) => {
        const warehouse = warehouses.find(
          w => w.name.toLowerCase() === name.toLowerCase()
        );
        return warehouse ? [...acc, warehouse.idwarehouse] : acc;
      }, []);

      if (warehouseIDs.includes(ATRIKOM_WAREHOUSE_ID)) {
        tags.push(CARGO_CARE_TAG_ID);
      } else if (warehouseIDs.includes(GURMELS_WAREHOUSE_ID)) {
        tags.push(CH_WAREHOUSE_TAG_ID);
      }

      let product = new Product({ code: productCode, amount, price });
      const picqerProduct = product.findByCode(products);
      if (picqerProduct) {
        product.id = picqerProduct.idproduct;
      }

      const order = new Order({
        referenceID: referenceID,
        warehouses: warehouseIDs,
        products: product ? [product] : [],
        customerID: customer ? customer.idcustomer : null,
        files: [file],
        tags,
      });

      if (!!order.findByReference(orders)) {
        // Order is already present.
        duplicates.push(order);
      } else {
        const { isValid } = order.validate();
        if (isValid) {
          const sibling = newOrders.find(order.isSibling, order);
          sibling ? sibling.mergeProductsFrom(order) : newOrders.push(order);
        } else {
          failures.push(order);
        }
      }
    }
  }

  return { orders: newOrders, failures, duplicates };
};

const parseRow = row => {
  const [
    customerID,
    referenceID,
    ,
    productCode,
    amount,
    price,
    warehouseNames,
    tags,
  ] = row;

  return {
    customerID: customerID ? customerID : null,
    referenceID: referenceID ? referenceID : null,
    productCode: productCode ? productCode : null,
    amount: amount ? amount : null,
    price: price ? price : null,
    warehouseNames: warehouseNames ? warehouseNames.split(',') : [],
    tags: tags ? tags.split(',') : [],
  };
};
