import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FeedbackPaper from './../common/FeedbackPaper';
import JsonPaper from './../common/JsonPaper';

const useStyles = makeStyles(theme => ({
  content: {
    flexDirection: 'column',
  },
  title: {
    '& span': {
      margin: theme.spacing(0, 2),
      '&:first-child': { marginLeft: 0 },
      '&:last-child': { marginRight: 0 },
    },
  },
}));

const ParseOrderExpansion = props => {
  const classes = useStyles();
  const { order } = props;
  const { isValid, msg } = order.validate();
  return (
    <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={classes.title}>
          <Typography component="span">
            {order.referenceID || 'Order Reference not found.'}
          </Typography>
          <Typography component="span">
            {order.products.length} Product{order.products.length > 1 && 's'}
          </Typography>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.content}>
        <FeedbackPaper variant={isValid ? 'success' : 'error'}>
          <Typography component="p">File(s): {order.filenames}</Typography>
          {!isValid && <Typography component="p">Error: {msg}</Typography>}
        </FeedbackPaper>
        <JsonPaper data={order.apiModel()} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ParseOrderExpansion.propTypes = {
  order: PropTypes.object.isRequired,
};

export default ParseOrderExpansion;
