import axios from 'axios';

class Product {
  id = null;
  amount = null;
  price = null;
  code = '';

  constructor({ id, amount, price, code }) {
    if (id) {
      this.id = id;
    }
    if (amount) {
      this.amount = parseInt(amount);
    }
    if (price) {
      this.price = parseFloat(price);
    }
    if (code) {
      this.code = code;
    }
  }

  findByCode(products) {
    return products.find(p => {
      return p.productcode.toLowerCase() === this.code.toLowerCase();
    });
  }

  toCSVRow(products) {
    const product = products.find(p => p.idproduct === this.id);
    const code = product ? product.productcode : '';
    return [code, this.amount, this.price];
  }

  validate() {
    const error = msg => ({ isValid: false, msg });
    if (!this.id) {
      return error('Missing required field "idproduct"');
    }

    if (isNaN(this.price)) {
      return error(`Price should be a number: ${this.price}"`);
    }

    if (isNaN(this.amount)) {
      return error(`Amount should be a number: ${this.price}"`);
    }

    return { isValid: true };
  }

  apiModel() {
    return {
      idproduct: this.id,
      amount: this.amount,
      price: this.price,
    };
  }
}

export default Product;
