import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles(theme => ({
  appBar: { position: 'relative' },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const DialogAppBar = ({ onClose, children, showCloseButton }) => {
  const classes = useStyles();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        {children}
        {showCloseButton && (
          <Button color="inherit" onClick={onClose}>
            Close
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

DialogAppBar.propTypes = {
  onClose: PropTypes.func.isRequired,
};

DialogAppBar.defaultProps = {
  showCloseButton: true,
};

export default DialogAppBar;
