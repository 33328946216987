import Validator from 'validatorjs';
import papa from 'papaparse';
import { red, green, blue, pink, orange } from '@material-ui/core/colors';

export const parseFileAsync = file => {
  return new Promise((resolve, reject) => {
    papa.parse(file, {
      skipEmptyLines: true,
      complete: results => {
        const data = results.data.filter(row => row.join('').trim().length > 0);
        resolve({ data, file });
      },
      error: (err, file) => reject({ err, file }),
    });
  });
};

export const parseJWT = token => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const validateData = (data, rules) => {
  const validation = new Validator(data, rules);
  const validationResponse = { isValid: validation.passes() };
  if (!validationResponse.isValid) {
    validationResponse.errors = validation.errors.all();
  }
  return validationResponse;
};

export const getRandomColor = theme => {
  const colors = [
    ...Object.values(red),
    ...Object.values(green),
    ...Object.values(blue),
    ...Object.values(pink),
    ...Object.values(orange),
  ].filter(c => c !== '#fff');

  const randIndex = Math.floor(Math.random() * Math.floor(colors.length));
  return colors[randIndex];
};
