import { useRef, useMemo, useReducer, useEffect } from 'react';

function withLogger(dispatch) {
  return function(action) {
    // console.groupEnd();
    // console.groupCollapsed('Action Type:', action.type);
    return dispatch(action);
  };
}

function useReducerWithLogger(...args) {
  const initialState = args[2];
  let prevState = useRef(initialState);
  const [state, dispatch] = useReducer(...args);

  const dispatchWithLogger = useMemo(() => {
    return withLogger(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (state !== initialState) {
      // console.log('Prev state: ', prevState.current);
      // console.log('Next state: ', state);
      // console.groupEnd();
    }
    prevState.current = state;
  }, [initialState, state]);

  return [state, dispatchWithLogger];
}

export default useReducerWithLogger;
