import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  codePaper: {
    padding: theme.spacing(3, 2),
    background: theme.palette.grey[100],
    width: '100%',
  },
}));

const JsonPaper = ({ data }) => {
  const classes = useStyles();

  const prettyJSON = data => {
    const parse = data => {
      let obj = {};
      try {
        obj = JSON.parse(data);
      } catch (e) {
        // can be ignored.
      }
      return obj;
    };

    const obj = typeof data === 'string' ? parse(data) : data;
    return JSON.stringify(obj, null, 2);
  };

  return (
    <Paper className={classes.codePaper}>
      <pre>{prettyJSON(data)}</pre>
    </Paper>
  );
};

JsonPaper.propTypes = {
  data: PropTypes.any.isRequired,
};

export default JsonPaper;
