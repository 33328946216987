import React from 'react';
import queryString from 'query-string';
import { withRouter, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useSnackbar } from 'notistack';

import ForgotPasswordForm from './ForgotPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  linkWrapper: {
    margin: theme.spacing(2, 0),
    color: theme.palette.secondary.main,
    fontSize: '1.2em',
    textAlign: 'end',
  },
}));

const ResetPassword = ({ location, history }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  let { token } = queryString.parse(location.search);
  const onResetSuccess = () => {
    enqueueSnackbar('Reset Password done!', { variant: 'success' });
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        {!token && <ForgotPasswordForm />}
        {token && (
          <ResetPasswordForm token={token} onSuccess={onResetSuccess} />
        )}

        <div className={classes.linkWrapper}>
          <Link to="/login">Back to Login</Link>
        </div>
      </Container>
    </div>
  );
};

ResetPassword.propTypes = {};

export default withRouter(ResetPassword);
