import axios from 'axios';

class Order {
  referenceID = null;
  customerID = null;
  warehouses = [];
  tags = [];
  products = [];
  files = [];

  constructor({ referenceID, customerID, warehouses, tags, products, files }) {
    if (referenceID) {
      this.referenceID = referenceID;
    }
    if (customerID) {
      this.customerID = parseInt(customerID);
    }
    if (warehouses) {
      this.warehouses = warehouses;
    }
    if (products) {
      this.products = products;
    }
    if (tags) {
      this.tags = tags;
    }
    if (files) {
      this.files = files;
    }
  }

  get filenames() {
    return this.files.map(file => file.name).join(', ');
  }

  findByReference(orders) {
    return orders.find(order => {
      const a = this.referenceID || '';
      const b = order.reference || '';
      return a.toLowerCase() === b.toLowerCase();
    });
  }

  validate() {
    const error = msg => ({ isValid: false, msg });
    if (!this.referenceID) {
      return error('Missing required field "reference"');
    }

    if (!this.customerID) {
      return error('Missing required field "customerID"');
    }

    if (!this.warehouses) {
      return error('Missing required field "warehouses"');
    }

    let err;
    this.products.forEach((product, idx) => {
      const { isValid, msg } = product.validate();
      if (!isValid) {
        err = `Product Nr. ${idx} - ${msg}`;
      }
    });

    return { isValid: !err, msg: err };
  }

  isSibling(target) {
    return (
      this.referenceID === target.referenceID &&
      this.customerID === target.customerID &&
      this.tags.join('') === target.tags.join('')
    );
  }

  toCSVRow(warehouses, customers, products) {
    // eslint-disable-next-line
    const warehouseStrings = this.warehouses.reduce((acc, id) => {
      const warehouse = warehouses.find(ww => ww.idwarehouse === id);
      return warehouse ? [...acc, warehouse.name] : acc;
    }, []);

    let customerid;
    const customer = customers.find(
      c => parseInt(c.idcustomer) === parseInt(this.customerID)
    );

    if (customer) {
      customerid = customer.customerid;
    }

    const product = this.products[0];
    return [
      customerid,
      this.referenceID,
      new Date().toLocaleDateString(),
      ...product.toCSVRow(products),
      warehouseStrings.join(','),
      this.tags.join(','),
    ];
  }

  apiModel() {
    return {
      reference: this.referenceID,
      idcustomer: this.customerID,
      tags: this.tags,
      warehouses: this.warehouses,
      products: this.products.map(product => product.apiModel()),
      partialdelivery: false,
    };
  }

  mergeProductsFrom(...targets) {
    for (let target of targets) {
      target.products.forEach(product => this.products.push(product));
      const filepaths = this.files.map(file => file.path);
      const newFiles = target.files.filter(
        file => !filepaths.includes(file.path)
      );
      this.files.push(...newFiles);
    }
  }

  unMergeProducts() {
    const newOrders = [];
    this.products.forEach((product, i) => {
      if (i > 0) {
        newOrders.push(
          new Order({
            referenceID: this.referenceID,
            customerID: this.customerID,
            products: [product],
            tags: this.tags,
            warehouses: this.warehouses,
          })
        );
      }
    });

    this.products = [this.products[0]];
    return newOrders;
  }

  upload() {
    return axios.post('/picqer/orders', this.apiModel());
  }
}

export default Order;
