import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import ParseOrderExpansion from './ParseOrderExpansion';
import FeedbackPaper from '../common/FeedbackPaper';
import DialogAppBar from '../common/DialogAppBar';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  codePaper: {
    padding: theme.spacing(3, 2),
    background: theme.palette.grey[100],
    width: '100%',
  },
  expansionContent: {
    flexDirection: 'column',
  },
  listAppBar: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: theme.palette.error.main,
  },
  listAppBarSuccess: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: green[500],
  },
  listAppBarWarning: {
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: orange[500],
  },
  list: {
    marginBottom: theme.spacing(2),
    '& > h1': {
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ParseConfirmDialog = props => {
  const {
    open,
    onCancel,
    onProceed,
    orders,
    invalidOrders,
    duplicateOrders,
  } = props;
  const classes = useStyles();
  const [showOrders, setShowOrders] = useState(true);
  const [showDuplicates, setShowDuplicates] = useState(false);
  const toggleShowOrders = () => setShowOrders(!showOrders);
  const toggleShowDuplicates = () => setShowDuplicates(!showDuplicates);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={'xl'}
      onClose={onCancel}
      scroll="paper"
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <DialogAppBar onClose={onCancel}>
        <Typography variant="h6" className={classes.title}>
          Confirm Upload | Parse Feedback
        </Typography>
      </DialogAppBar>

      <DialogContent>
        <FeedbackPaper>
          <Typography component="p">
            Total Orders:{' '}
            {[...orders, ...invalidOrders, ...duplicateOrders].length}
          </Typography>
          <Typography component="p">Valid Orders: {orders.length}</Typography>
          <Typography component="p">
            Invalid Orders: {invalidOrders.length}
          </Typography>
          <Typography component="p">
            Duplicated Orders: {duplicateOrders.length}
          </Typography>
        </FeedbackPaper>

        {!orders.length && (
          <FeedbackPaper variant="error">
            <Typography component="p">
              Looks like we have not a single valid order. Please fix your Files
              and try again!
            </Typography>
          </FeedbackPaper>
        )}

        {!orders.length && (
          <FeedbackPaper variant="warning">
            <Typography component="p">
              Looks like we found duplicated orders. You can not import orders
              which already exist in picqer. We will ignore those orders.
            </Typography>
          </FeedbackPaper>
        )}

        {!!duplicateOrders.length && (
          <div className={classes.list}>
            <AppBar className={classes.listAppBarWarning}>
              <Toolbar>
                <ErrorOutlineIcon />
                <Typography variant="h6" className={classes.title}>
                  Duplicated Rows ({duplicateOrders.length})
                </Typography>
                <Button color="inherit" onClick={toggleShowDuplicates}>
                  {showDuplicates ? 'Hide' : 'Show'}
                </Button>
              </Toolbar>
            </AppBar>
            {showDuplicates &&
              duplicateOrders.map((order, i) => (
                <ParseOrderExpansion key={i} order={order} />
              ))}
          </div>
        )}

        {!!invalidOrders.length && (
          <div className={classes.list}>
            <AppBar className={classes.listAppBar}>
              <Toolbar>
                <ErrorOutlineIcon />
                <Typography variant="h6" className={classes.title}>
                  Invalid Rows ({invalidOrders.length})
                </Typography>
              </Toolbar>
            </AppBar>
            {invalidOrders.map((order, i) => (
              <ParseOrderExpansion key={i} order={order} />
            ))}
          </div>
        )}

        {!!orders.length && (
          <div className={classes.list}>
            <AppBar className={classes.listAppBarSuccess}>
              <Toolbar>
                <DoneAllIcon />
                <Typography variant="h6" className={classes.title}>
                  Valid Orders ({orders.length})
                </Typography>
                <Button color="inherit" onClick={toggleShowOrders}>
                  {showOrders ? 'Hide' : 'Show'}
                </Button>
              </Toolbar>
            </AppBar>
            {showOrders &&
              orders.map((order, i) => (
                <ParseOrderExpansion key={i} order={order} />
              ))}
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onProceed} disabled={!orders.length} color="primary">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ParseConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  invalidOrders: PropTypes.array.isRequired,
  duplicateOrders: PropTypes.array.isRequired,
};

export default ParseConfirmDialog;
