import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    minWidth: '120px',
  },
  chips: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

function FileActions({ files, onDeleteFile, onSubmit, isDisabled }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.chips}>
        {files.map((file, i) => (
          <Chip
            key={i}
            icon={<FileCopyIcon />}
            color="primary"
            label={file.name}
            disabled={isDisabled}
            onDelete={() => onDeleteFile(i)}
          />
        ))}
      </div>
      <Fab
        disabled={isDisabled}
        variant="extended"
        aria-label="delete"
        color="secondary"
        className={classes.fab}
        onClick={onSubmit}
      >
        <CloudUploadIcon className={classes.extendedIcon} />
        Upload
      </Fab>
    </div>
  );
}

FileActions.propTypes = {
  files: PropTypes.array.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

FileActions.defaultProps = {
  isDisabled: false,
};

export default FileActions;
