import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import debounce from 'lodash.debounce';

import { validateData } from '../../util';
import TextField from '@material-ui/core/TextField';
import DialogAppBar from '../common/DialogAppBar';
import FeedbackPaper from '../common/FeedbackPaper';
import useReducerWithLogger from '../../hooks/useReducerWithLogger';
import { AppContext } from '../App';

const DEBOUNCE_MS = 250;

const formRules = {
  username: 'required',
  email: 'required|email',
  password: 'required|confirmed|min:8',
  password_confirmation: 'required',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_VALUE':
      return {
        ...state,
        formDirty: true,
        formValues: { ...state.formValues, [action.name]: action.value },
      };
    case 'SET_FORM_ERRORS':
      return {
        ...state,
        formErrors: { ...state.formErrors, [action.name]: action.error },
      };
    case 'RESET_FORM':
      return {
        ...state,
        formValues: { ...action.values },
        formErrors: {},
        formDirty: false,
      };
    default:
      throw Error(`wut the action? ${action.type}`);
  }
};

const initialState = {
  formValues: {
    id: null,
    username: '',
    email: '',
    password: '',
    password_confirmation: '',
    admin: false,
  },
  formErrors: {},
  formDirty: false,
};

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
  },
  form: {
    flexDirection: 'column',
    width: '100%',
    display: 'flex',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserFormDialog = props => {
  const [state, dispatch] = useReducerWithLogger(reducer, initialState);
  const { open, onCancel, onSubmit, user } = props;
  const context = useContext(AppContext);
  useEffect(() => {
    if (open && user && user.id) {
      const values = ['username', 'id', 'admin', 'email'];
      values.forEach(name =>
        dispatch({
          type: 'UPDATE_VALUE',
          name,
          value: user[name],
        })
      );
    }
  }, [dispatch, open, user]);

  const classes = useStyles();
  const { formValues, formErrors } = state;

  const [debouncedValidateData] = useState(() =>
    debounce(
      (data, rules, name) => {
        const { isValid, errors } = validateData(data, rules);
        if (name) {
          if (!isValid) {
            dispatch({ type: 'SET_FORM_ERRORS', name, error: errors[name] });
          } else {
            dispatch({ type: 'SET_FORM_ERRORS', name, error: null });
          }
        }
      },
      DEBOUNCE_MS,
      { leading: true }
    )
  );

  useEffect(() => {
    if (open) return;

    if (debouncedValidateData) {
      debouncedValidateData.cancel();
    }
    return () => {
      if (debouncedValidateData) {
        debouncedValidateData.cancel();
      }
    };
  }, [debouncedValidateData, open]);

  const handleChangeCheckbox = event => {
    const { name, checked } = event.target;
    dispatch({
      type: 'UPDATE_VALUE',
      name,
      value: checked,
    });
  };

  const handleChangeInput = event => {
    const { name, value } = event.target;
    dispatch({
      type: 'UPDATE_VALUE',
      name,
      value,
    });

    const rules = { [name]: formRules[name] };
    const data = { [name]: value };
    if (rules[name].includes('confirmed')) {
      const confName = `${name}_confirmation`;
      data[confName] = state.formValues[confName];
    }

    let newName = name;
    if (name.includes('_confirmation')) {
      newName = name.replace('_confirmation', '');
      data[newName] = state.formValues[newName];
      rules[newName] = formRules[newName];
    }

    debouncedValidateData(data, rules, newName);
  };

  const handleClose = () => {
    dispatch({
      type: 'RESET_FORM',
      values: initialState.formValues,
      errors: {},
    });

    onCancel();
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { id, username, password, admin, email } = formValues;
    dispatch({
      type: 'RESET_FORM',
      values: initialState.formValues,
      errors: {},
    });

    let user = { id, username, email, admin };
    if (!id) {
      // Create context
      user = { ...user, password };
    }

    onSubmit(user);
  };

  const renderErrors = () => {
    const { formErrors } = state;
    const errors = Object.values(formErrors).reduce(
      (acc, val) => (val !== null ? [...acc, ...val] : [...acc]),
      []
    );

    if (!errors.length) return null;

    return (
      <FeedbackPaper variant="error">
        {errors.map((error, i) => (
          <Typography key={i} component="p">
            {error}
          </Typography>
        ))}
      </FeedbackPaper>
    );
  };

  const hasErrors = () => {
    const values = state.formValues;
    const hasEmptyFields = ['username', 'email'].some(i => values[i] === '');

    if (hasEmptyFields) {
      return true;
    }

    const errors = Object.values(state.formErrors).reduce(
      (acc, val) => (val !== null ? [...acc, ...val] : [...acc]),
      []
    );
    return !!errors.length;
  };

  const getTitle = () => {
    if (formValues.id && user) {
      return `Edit User ${user.username}`;
    }

    return `Create User ${formValues.username}`;
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={'md'}
      onClose={handleClose}
    >
      <DialogAppBar onClose={handleClose} showCloseButton={false}>
        <Typography variant="h6" className={classes.title} align="center">
          {getTitle()}
        </Typography>
      </DialogAppBar>

      <DialogContent>
        {renderErrors()}

        <form
          className={classes.form}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            error={!!formErrors.username}
            name="username"
            required
            autoFocus={true}
            variant="outlined"
            label="Username"
            value={formValues.username}
            onChange={handleChangeInput}
            margin="normal"
            onBlur={handleChangeInput}
          />
          <TextField
            error={!!formErrors.email}
            name="email"
            required
            variant="outlined"
            label="E-Mail"
            value={formValues.email}
            onChange={handleChangeInput}
            margin="normal"
            onBlur={handleChangeInput}
          />
          {!state.formValues.id && (
            <React.Fragment>
              <TextField
                error={!!formErrors.password}
                name="password"
                type="password"
                required
                variant="outlined"
                label="Password"
                value={formValues.password}
                onChange={handleChangeInput}
                margin="normal"
                onBlur={handleChangeInput}
              />
              <TextField
                error={!!formErrors.password_confirmation}
                name="password_confirmation"
                type="password"
                required
                variant="outlined"
                label="Confirm Password"
                value={formValues.password_confirmation}
                onChange={handleChangeInput}
                margin="normal"
                onBlur={handleChangeInput}
              />
            </React.Fragment>
          )}
          {context.user.admin && (
            <FormControlLabel
              control={
                <Switch
                  checked={formValues.admin}
                  value={formValues.admin}
                  onChange={handleChangeCheckbox}
                  inputProps={{
                    'aria-label': 'secondary checkbox',
                    name: 'admin',
                  }}
                />
              }
              label="Admin?"
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!state.formDirty || hasErrors()}
          color="primary"
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UserFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
};

UserFormDialog.defaultProps = {};

export default UserFormDialog;
