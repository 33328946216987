import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function NoInternetDialog({ open }) {
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Looks like you are Offline
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please check your internet connection.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
