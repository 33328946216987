import React, { useContext, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { AppContext } from './App';
import UserAvatar from './users/UserAvatar';
import UserFormDialog from './users/UserFormDialog';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  links: {
    marginRight: theme.spacing(2),
  },
}));

const AppToolbar = ({ onLogout }) => {
  const classes = useStyles();
  const context = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showUserDialog, setShowUserDialog] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitUserForm = async formValues => {
    setAnchorEl(null);
    setShowUserDialog(false);
    const { id, username, admin, email } = formValues;
    const user = { id, username, admin, email };
    try {
      await axios.post(`/users/${user.id}`, user);
      context.setUser(user);
    } catch (e) {
      console.log('could not update');
    }
  };

  return (
    <React.Fragment>
      <AppBar position="relative">
        <Toolbar className={classes.toolbar}>
          <div className={classes.title}>
            <Link to="/">
              <Typography variant="h6" color="inherit" noWrap>
                BAM Tools
              </Typography>
            </Link>
          </div>

          {context.user.admin && (
            <div className={classes.links}>
              <Link to="/users">
                <Button color="inherit">Users</Button>
              </Link>
            </div>
          )}

          {context.authenticated && (
            <React.Fragment>
              <Button onClick={handleClick}>
                <UserAvatar
                  user={context.user}
                  background={context.userColor}
                />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setShowUserDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  Edit Profile
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    onLogout();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>

      <UserFormDialog
        onSubmit={handleSubmitUserForm}
        open={showUserDialog}
        onCancel={() => setShowUserDialog(false)}
        user={context.user}
      />
    </React.Fragment>
  );
};

AppToolbar.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default AppToolbar;
