import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AdminRoute = ({ isAdmin, children, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAdmin === true ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export default AdminRoute;
