import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';

import FeedbackPaper from '../common/FeedbackPaper';
import DialogAppBar from '../common/DialogAppBar';

const useStyles = makeStyles(theme => ({
  title: {
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteUserConfirm = props => {
  const { open, onCancel, onProceed, user } = props;
  const classes = useStyles();
  const username = (user || {}).username || 'Username not found';
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth={true}
      maxWidth={'sm'}
      onClose={onCancel}
    >
      <DialogAppBar onClose={onCancel} showCloseButton={false}>
        <Typography variant="h6" className={classes.title} align="center">
          Are you sure?
        </Typography>
      </DialogAppBar>

      <DialogContent>
        <FeedbackPaper>
          <Typography component="p" align="center">
            {`You're about to delete the user`} <b>{username}</b>. Are you sure
            you want to proceed?
          </Typography>
        </FeedbackPaper>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onProceed} color="primary">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteUserConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
};

DeleteUserConfirm.defaultProps = {
  user: {},
};

export default DeleteUserConfirm;
