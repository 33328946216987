import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import FeedbackPaper from './../common/FeedbackPaper';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
});

const AsyncInit = ({ onInit, children }) => {
  const isMounted = useRef(true);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const classes = useStyles();

  const init = useCallback(async () => {
    const saveSetState = callback => {
      if (isMounted.current) {
        callback();
      }
    };

    saveSetState(() => setInitialized(false));
    try {
      await onInit();
      saveSetState(() => setError(null));
    } catch (e) {
      saveSetState(() => setError(e));
    } finally {
      saveSetState(() => setInitialized(true));
    }
  }, [onInit]);

  useEffect(() => {
    init();
    return () => (isMounted.current = false);
  }, [init]);

  if (error && initialized) {
    return (
      <div className={classes.root}>
        <FeedbackPaper variant="error">
          {error.response && (
            <Typography variant="h6" component="p" align="center">
              {error.response.status}: {error.response.statusText}
            </Typography>
          )}
          <Typography component="p" align="center">
            Uups. Something went wrong while initializing the page. Please
            reload the page or click the following button to retry.
          </Typography>
          <div className={classes.root}>
            <Button color="inherit" onClick={init}>
              Retry
            </Button>
          </div>
        </FeedbackPaper>
      </div>
    );
  }

  if (!initialized) {
    return (
      <div className={classes.root}>
        <CircularProgress />
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

AsyncInit.propTypes = {
  onInit: PropTypes.func.isRequired,
};

export default AsyncInit;
