import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import OrderFeedbackPaper from './OrderFeedbackPaper';
import JsonPaper from './../common/JsonPaper';

const useStyles = makeStyles(theme => ({
  expansionContent: {
    flexDirection: 'column',
  },
}));

const OrderFeedbackExpansion = props => {
  const classes = useStyles();

  const { item } = props;
  const { res } = item;
  const isFailure = res.status < 200 || res.status > 299;
  return (
    <ExpansionPanel TransitionProps={{ unmountOnExit: true }}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {' '}
          {item.order.reference || 'Order Reference not found.'}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.expansionContent}>
        <OrderFeedbackPaper
          variant={isFailure ? 'error' : 'success'}
          status={res.status}
          statusText={
            isFailure
              ? res.data.error_message || res.statusText
              : res.statusText
          }
          files={item.order.files}
        />

        <JsonPaper data={isFailure ? res.config.data : res.data} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

OrderFeedbackExpansion.propTypes = {
  item: PropTypes.object.isRequired,
};

export default OrderFeedbackExpansion;
