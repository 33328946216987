import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles(theme => ({
  dropzone: {
    borderColor: theme.palette.grey[200],
    minHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: 'dashed 5px',
    alignItems: 'center',
    color: theme.palette.grey[600],

    '&:focus': {
      outline: 'none',
    },

    '&:hover': {
      borderColor: props =>
        !props.isDisabled ? theme.palette.secondary.light : null,
      cursor: props => (!props.isDisabled ? 'pointer' : null),
    },
  },
}));

function Dropzone(props) {
  const { onFileDrop, isDisabled, children } = props;
  const classes = useStyles(props);

  const onDrop = useCallback(onFileDrop, [onFileDrop]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={classes.dropzone}>
      <input {...getInputProps()} disabled={isDisabled} />
      {children}
      {!children && (
        <Typography variant="h4" component="p">
          {isDragActive ? (
            <React.Fragment>Drop here</React.Fragment>
          ) : (
            <React.Fragment>
              Drag 'n' drop or click to select files
            </React.Fragment>
          )}
        </Typography>
      )}
    </div>
  );
}

Dropzone.propTypes = {
  onFileDrop: PropTypes.func.isRequired,
};
Dropzone.defaultProps = {
  isDisabled: false,
};

export default Dropzone;
