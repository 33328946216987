import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Slide from '@material-ui/core/Slide';
import green from '@material-ui/core/colors/green';

import OrderFeedbackExpansion from './OrderFeedbackExpansion';
import FeedbackPaper from './../common/FeedbackPaper';
import DialogAppBar from '../common/DialogAppBar';

const useStyles = makeStyles(theme => ({
  listTitleFailure: {
    position: 'relative',
    backgroundColor: theme.palette.error.main,
    boxShadow: 'none',
  },
  listTitleSuccess: {
    position: 'relative',
    backgroundColor: green[500],
    boxShadow: 'none',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  list: {
    marginBottom: theme.spacing(2),

    '& > h1': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UploadFeedbackDialog(props) {
  const { open, onClose, failures, successs, onExport } = props;
  const [showSuccesss, setShowSuccesss] = useState(false);
  const [showFailures, setShowFailures] = useState(true);
  const classes = useStyles(props);
  const getFileNames = () => {
    const fileNames = [];
    [...successs, ...failures].forEach(item => {
      fileNames.push(...item.order.files.map(file => file.name));
    });
    return [...new Set(fileNames)];
  };

  const toggleSuccess = () => setShowSuccesss(!showSuccesss);
  const toggleFailures = () => setShowFailures(!showFailures);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogAppBar onClose={onClose}>
        <Typography variant="h6" className={classes.title}>
          Upload Feedback
        </Typography>
      </DialogAppBar>

      <DialogContent>
        <FeedbackPaper>
          <Typography component="p">
            Total Orders: {successs.length + failures.length}
          </Typography>
          <Typography component="p">Successs: {successs.length}</Typography>
          <Typography component="p">Failures: {failures.length}</Typography>
          <Typography component="p">
            From files: {getFileNames().join(', ')}
          </Typography>
        </FeedbackPaper>

        <div className={classes.listWrapper}>
          {!!failures.length && (
            <div className={classes.list}>
              <AppBar className={classes.listTitleFailure}>
                <Toolbar>
                  <ErrorOutlineIcon />
                  <Typography variant="h6" className={classes.title}>
                    Failures
                  </Typography>
                  <Button color="inherit" onClick={toggleFailures}>
                    {showFailures ? 'Hide' : 'Show'}
                  </Button>
                  <Button
                    color="inherit"
                    onClick={() => onExport(failures, 'failures')}
                  >
                    Export
                  </Button>
                </Toolbar>
              </AppBar>

              {showFailures &&
                failures.map((item, i) => (
                  <OrderFeedbackExpansion item={item} key={i} />
                ))}
            </div>
          )}

          {!!successs.length && (
            <div className={classes.list}>
              <AppBar className={classes.listTitleSuccess}>
                <Toolbar>
                  <DoneAllIcon />
                  <Typography variant="h6" className={classes.title}>
                    Success
                  </Typography>
                  <Button color="inherit" onClick={toggleSuccess}>
                    {showSuccesss ? 'Hide' : 'Show'}
                  </Button>
                </Toolbar>
              </AppBar>
              {showSuccesss &&
                successs.map((item, i) => (
                  <OrderFeedbackExpansion item={item} key={i} />
                ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

UploadFeedbackDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  failures: PropTypes.array.isRequired,
  successs: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default UploadFeedbackDialog;
