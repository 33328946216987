import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { getRandomColor } from '../../util';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: props => props.background || props.randomBackground,
  },
}));

const UserAvatar = props => {
  const randomBackground = useRef(getRandomColor());
  const classes = useStyles({
    ...props,
    randomBackground: randomBackground.current,
  });
  return (
    <Avatar className={classes.root}>
      {props.user.username[0].toUpperCase()}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  background: PropTypes.string,
};

UserAvatar.defaultProps = {};

export default UserAvatar;
