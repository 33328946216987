import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import PropTypes from 'prop-types';

import FeedbackPaper from '../common/FeedbackPaper';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
}));

const ForgotPasswordForm = () => {
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const classes = useStyles();
  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post('/forgot-password', { username });
      setSuccess(true);
      setError(null);
      setUsername('');
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {!error && !success && (
        <FeedbackPaper variant="info">
          <Typography component="p">
            Please provide your <b>Username</b>. We will send an E-Mail to the
            address linked to the account with further informations.
          </Typography>
        </FeedbackPaper>
      )}

      {success && (
        <FeedbackPaper variant="success">
          <Typography component="p">
            Reset Link was sent to your E-Mail Adress.
          </Typography>
        </FeedbackPaper>
      )}

      {error && (
        <FeedbackPaper variant="error">
          <Typography component="p">
            Snap. Something went wrong. Please try again.
          </Typography>
        </FeedbackPaper>
      )}

      <form
        className={classes.form}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <TextField
          className={classes.textField}
          required
          autoFocus={true}
          variant="outlined"
          label="Username"
          value={username}
          onChange={e => setUsername(e.target.value)}
          margin="normal"
        />

        <Button
          disabled={!username || loading}
          size="large"
          variant="contained"
          color="primary"
          type="submit"
        >
          Request link
        </Button>
      </form>
    </React.Fragment>
  );
};

ForgotPasswordForm.propTypes = {};

export default ForgotPasswordForm;
