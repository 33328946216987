import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const VARIANT_INFO = 'info';
const VARIANT_SUCCESS = 'success';
const VARIANT_ERROR = 'error';
const VARIANT_WARNING = 'warning';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2),
    background: props =>
      props.variant === VARIANT_INFO
        ? blue[100]
        : props.variant === VARIANT_ERROR
        ? red[100]
        : props.variant === VARIANT_WARNING
        ? orange[100]
        : green[100],
    borderLeftStyle: 'solid',
    borderLeftWidth: theme.spacing(1),
    borderLeftColor: props =>
      props.variant === VARIANT_INFO
        ? blue[500]
        : props.variant === VARIANT_ERROR
        ? red[500]
        : props.variant === VARIANT_WARNING
        ? orange[500]
        : green[500],
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    borderRadius: '0',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  column: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const FedbackPaper = props => {
  const { children, variant } = props;
  const classes = useStyles(props);
  return (
    <Paper className={classes.root}>
      <div className={classes.iconWrapper}>
        {variant === VARIANT_INFO && <InfoIcon />}
        {variant === VARIANT_SUCCESS && <DoneAllIcon />}
        {variant === VARIANT_ERROR && <ErrorOutlineIcon />}
        {variant === VARIANT_WARNING && <ErrorOutlineIcon />}
      </div>
      <div className={classes.column}>{children}</div>
    </Paper>
  );
};

FedbackPaper.defaultProps = {
  variant: VARIANT_INFO,
};

export default FedbackPaper;
